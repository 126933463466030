import React from "react";
import ContactOneForm from "./ContactOneForm";
import ContactOneInfoItem from "./ContactOneInfoItem";
import DiscordIcon from "../../assets/img/icons2/icons_site-01.png";
import InstagramIcon from "../../assets/img/icons2/icons_site-03.png";
import XIcon from "../../assets/img/icons2/icons_site-02.png";

const ContactOne = () => {
  const info_items = [
    {
      icon: DiscordIcon,
      content: <>Join our Discord</>,
      link: "https://discord.gg/vzWU7yMCut",
    },
    {
      icon: InstagramIcon,
      content: <>Follow us on instagram</>,
      link: "https://www.instagram.com/kumagamesio/",
    },
    {
      icon: XIcon,
      content: <>Check out all our
      <br></br> updates and news on X</>,
      link: "https://twitter.com/kumagamesio",
    },
  ];

  return (
    <section id="contact" className="contact-area pt-70 pb-110">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-70">
              <span className="sub-title">Contact</span>
              <h2 className="title">
                <span>Contact</span> Kuma Games
              </h2>
            </div>
          </div>
        </div>

        {/* secction info */}
        <div className="contact-info-wrap">
          <div className="row justify-content-center">
            {info_items.map((x, index) => (
              <div key={index} className="col-lg-4 col-sm-6">
                <ContactOneInfoItem item={x} />
              </div>
            ))}
          </div>
        </div>

        {/* section form */}
        {/* <ContactOneForm /> */}
      </div>
    </section>
  );
};

export default ContactOne;
