import React from "react";
import img01 from "../../assets/img/images/logo.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img style={{width:'50vh'}} src={img01} alt="" />
              {/* <img src={img02} alt="" className="img-two" /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">About Us</span>
                <h2 className="title">Kuma Games</h2>
              </div>
              <p>
                Welcome to Kuma Games, where innovation meets excitement in the
                world of robotics gaming. As industry pioneers, we're proud to
                unveil our debut entry into the thrilling realm of robot battle
                gaming.
                <br></br>
                <br></br>
                At Kuma Games, we're driven by a singular mission: to deliver an
                unparalleled gaming experience that seamlessly blends
                cutting-edge technology with adrenaline-fueled competition. Our
                debut game represents the culmination of tireless dedication and
                a relentless pursuit of excellence, setting the stage for future
                endeavors in the robotics gaming industry.
                <br></br>
                <br></br>
                Join us as we redefine the future of gaming, one epic battle at
                a time!
              </p>
              {/* <Link to="/" className="btn">
                Play Now
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
