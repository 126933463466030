import React from "react";
import cn from "classnames";

const ContactOneInfoItem = (props) => {
  const handleClick = () => {
    if (props.item.link) {
      window.open(props.item.link, "_blank");
    }
  };

  const iconStyle = {
    position: 'relative',
    cursor: 'pointer',
    '&:hover::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', 
    },
  };

  return (
    <div className="contact-info-item">
      <div className="icon" onClick={handleClick} style={iconStyle}>
        <img src={props.item.icon} alt={props.item.content} />
      </div>
      <div className="content">
        <p>{props.item.content}</p>
      </div>
    </div>
  );
};

export default ContactOneInfoItem;
