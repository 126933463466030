import React, { useState } from "react";
import x from "../../assets/img/icons2/icons_site-08.png";
import xblue from "../../assets/img/icons2/icons_site-08b.png";

const TeamOneItem = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="team-item">
      <div className="team-thumb">
        <img src={props.item.src} alt={props.item.name} />
      </div>
      <div className="team-content">
        <h2 className="title">{props.item.name}</h2>
        <span>{props.item.designation}</span>

        <ul className="team-social">
          {/* Ícone "X" */}
          <li>
            <a href={props.item.twitter} target="_blank" rel="noopener noreferrer">
              <img
                src={isHovered ? xblue : x} // Alterna entre xblue e x com base no estado de isHovered
                alt="x"
                style={{ width: '1.4vh', marginBottom: '3px' }}
                onMouseEnter={handleMouseEnter} // Chama handleMouseEnter quando o mouse entra
                onMouseLeave={handleMouseLeave} // Chama handleMouseLeave quando o mouse sai
              />
            </a>
          </li>
          {/* Ícone do LinkedIn */}
          <li>
            <a href={props.item.linkedin} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TeamOneItem;
